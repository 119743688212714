import { formatDate } from 'journey-ui'
import { formatSizeName, money } from '../helpers'

export abstract class PrintCanvas {
  lineY = 80
  textDistance = 40
  canvas: any
  fontSize = 28
  brand: Brand
  widthSize: number
  elements: any = []
  /* base64 */
  printLogo: string
  width: number
  height: number

  setFontSize(int) {
    this.fontSize = int
  }

  addSpace(dist = null) {
    if (!dist) {
      dist = this.textDistance
    }

    this.elements.push({
      type: 'space',
      distance: dist,
    })
  }

  addLine(dist = null, lineWidth = 5) {
    const lastElement = this.elements[this.elements.length - 1]

    if (lastElement.type === 'text') {
      this.elements[this.elements.length - 1].distance = 20
    }

    this.addSpace(dist)

    this.elements.push({
      type: 'line',
      lineWidth,
      distance: this.textDistance,
    })

    this.addSpace(dist)
  }

  getHeight() {
    let height = 0

    this.elements.forEach((element) => {
      height += element.distance + 2
    })

    return height
  }

  ctx() {
    return this.canvas.getContext('2d')
  }

  abstract buildCanvas(): Promise<any>

  autoSizeCanvas() {
    this.height = this.getHeight()

    this.width = 375

    if (this.widthSize === 80) {
      this.width = 550
    }

    if (this.canvas) {
      this.canvas.width = this.width
      this.canvas.height = this.height
    }
  }

  clearCanvas() {
    this.elements = []
    this.lineY = 0
    this.canvas = document.createElement('canvas')

    this.autoSizeCanvas() // needed for proper width calculations, but height will matter upon 2nd auto size before draw
  }

  async createCanvasEle() {
    this.clearCanvas()

    await this.buildCanvas()

    this.autoSizeCanvas()

    const ctx = this.ctx()

    ctx.fillStyle = 'rgb(255, 255, 255)'
    ctx.fillRect(0, 0, this.width, this.height)

    this.elements.forEach((element) => {
      switch (element.type) {
        case 'image': {
          const img = element.imgObject

          const xOffset = this.width - element.width

          ctx.drawImage(img, xOffset / 2, this.lineY, element.width, element.height)
          break
        }
        case 'text': {
          ctx.fillStyle = 'rgb(0,0,0)'
          ctx.font = `${element.fontSize}px Arial`

          ctx.textAlign = element.align

          ctx.fillText(element.line, (element.align === 'center' ? this.width / 2 : element.x), this.lineY)

          break
        }
        case 'space': {
        // do nothing, just adding to lineY

          break
        }
        case 'line': {
          ctx.beginPath()

          ctx.moveTo(0, this.lineY - element.lineWidth)
          ctx.lineTo(this.width, this.lineY - element.lineWidth)

          ctx.lineWidth = element.lineWidth
          ctx.stroke()

          break
        }
      // No default
      }

      this.lineY += element.distance
    })

    return this.canvas
  }

  getLines(text) {
    const ctx = this.ctx()
    const maxWidth = this.width

    const words = text.split(' ')
    const lines = []
    let currentLine = words[0]

    for (let i = 1; i < words.length; i++) {
      const word = words[i]
      const { width } = ctx.measureText(`${currentLine} ${word}`)
      if (width < maxWidth) {
        currentLine += ` ${word}`
      } else {
        lines.push(currentLine)
        currentLine = word
      }
    }
    lines.push(currentLine)
    return lines
  }

  writeImage(url: string, height: 'auto' | number = 'auto') {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.addEventListener('load', () => {
        const imgWidth = img.width
        const imgHeight = img.height

        this.elements.push({
          type: 'image',
          width: imgWidth,
          height: imgHeight,
          distance: imgHeight,
          imgObject: img,
        })

        resolve(true)
      })

      img.addEventListener('error', reject)

      // at
      img.crossOrigin = 'anonymous'
      img.src = url
    })
  }

  writeText(text = '', options: { align?: string, x?: number, fontSize?: number } = {} as any) {
    const { x = 0, align = 'left' } = options

    const fontSize = options.fontSize ?? this.fontSize

    // For getLines calcs
    this.ctx().fillStyle = 'rgb(0,0,0)'
    this.ctx().font = `${fontSize}px Arial`

    this.getLines(text).forEach((line) => {
      this.elements.push({
        line,
        type: 'text',
        fontSize,
        x,
        distance: this.textDistance,
        align,
      })
    })
  }

  minimizeText(str) {
    const wordLists = {
      LRG: ['Large'],
      SML: ['Small'],
      BYO: ['Build Your Own'],
      MED: ['Medium'],
      XLG: ['XLarge', 'Extra Large'],
      CHS: ['Cheese'],
      REG: ['Regular'],
      '"': ['Inch'],
      '': ['Hand tossed'],
    }

    for (const minimizedWord in wordLists) {
      if (!wordLists.hasOwnProperty(minimizedWord)) {
        continue
      }

      const words = wordLists[minimizedWord]

      words.forEach((word) => {
        str = str.replace(word, minimizedWord)
      })
    }

    return str
  }

  space(num = 0) {
    if (num <= 0) {
      return ''
    }

    return new Array(num + 1).join(' ')
  }

  writeItem(item: Item, x = 0, prefix = '') {
    let price = 'FREE'

    if (item.price > 0) {
      price = money(item.price)
    }

    prefix = prefix.length > 0 ? `${prefix} ` : ''

    const parts = [prefix, (item.quantity > 1 ? `${item.quantity}x` : ''), formatSizeName(item), this.minimizeText(item.name), `- ${price}`]

    this.writeText(parts.filter((s) => s !== '').join(' ').toUpperCase(), { align: 'left', x })

    if (item.details) {
      item.details.forEach((detail) => {
        this.writeText(`- ${detail}`, { align: 'left', x: x + 28 })
      })
    }
  }

  writeOrderType(order: Order) {
    if (order.type === 'delivery') {
      this.writeText(`*** ${order.delivery_quote_hash_id ? 'DOOR DASH DELIVERY' : 'DELIVERY'} ***`, { align: 'center' })
    } else {
      this.writeText('*** PICKUP ***', { align: 'center' })
    }

    if (order.is_cash) {
      this.writeText('Pay With Cash', { align: 'center' })
    } else {
      this.writeText('Paid For Online', { align: 'center' })
    }

    if (order.scheduled_at) {
      this.writeText(`Scheduled for ${formatDate(order.scheduled_at, 'MM/dd/Y h:mma')}`, { align: 'center' })
    } else if (order.wait_minutes) {
      this.writeText(`ETA ${formatDate(order.ready_start_at, 'h:mma')} - ${formatDate(order.ready_end_at, 'h:mma')}`, { align: 'center' })
    }
  }
}
