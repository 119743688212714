import { isServer } from 'journey-ui'

const audioRecords: Record<string, HTMLAudioElement> = {}

export function useAudio(url: string) {
  if (audioRecords[url]) {
    return audioRecords[url]
  }

  if (isServer()) {
    return
  }

  const audio = new Audio()
  audio.src = url
  audio.load()

  audioRecords[url] = audio

  return audioRecords[url]
}
