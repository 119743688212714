import { PizzaPrinter } from 'pizza-printer'
import { useCallback } from 'react'

export function usePrinterScanner() {
  return useCallback(async () => {
    const { devices } = await PizzaPrinter.getDevices()

    return devices
  }, [])
}
