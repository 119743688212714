import { useEffect } from 'react'

export function useDailyReboot() {
  /* Reload / refresh daily at 4am. */
  useEffect(() => {
    const t = setInterval(() => {
      // 4 am
      if (new Date().getHours() !== 4) {
        return
      }

      const lastDailyRestartAt = window.localStorage.getItem('lastDailyRestartAt')
      const curTime = Date.now()

      if (lastDailyRestartAt && parseInt(lastDailyRestartAt, 10) > (curTime - (1000 * 3600))) {
        return
      }

      window.localStorage.setItem('lastDailyRestartAt', `${curTime}`)

      setTimeout(() => {
        window.location.reload(true)
      }, 100)
    }, 60_000)

    return () => clearInterval(t)
  }, [])
}
