import { useContext } from 'react'
import { Capacitor } from '@capacitor/core'
import { PizzaPrinter } from 'pizza-printer'
import { JourneyAppContext } from 'journey-ui'
import { AppContext } from '../contexts/AppContext'
import { encodeCanvas } from '../helpers'
import { PrintCanvas } from '../util/PrintCanvas'

export function useCanvasPrinter(printers: Printer[]) {
  const { setError } = useContext(JourneyAppContext)

  return async (printCanvas: PrintCanvas) => {
    if (Capacitor.isNativePlatform()) {
      if (printers.length <= 0) {
        setError(new Error('You have not added any printers yet.'))
        return
      }

      const canvasEle = await printCanvas.createCanvasEle()

      PizzaPrinter.printImage({
        image: encodeCanvas(canvasEle),
        width: printCanvas.width,
        height: printCanvas.height,
        devices: printers.map((printer) => ({
          address: printer.address,
          is_usb: printer.is_usb,
          name: printer.name,
        })),
      })
        .catch(console.error)
    }
  }
}
