import { formatDate } from 'journey-ui'
import { PrintCanvas } from './PrintCanvas'
import { money } from '../helpers'

interface Options { padding: number, widthSize: number, fontSize: number }
export class OrderPrintCanvas extends PrintCanvas {
  order: Order
  brand: Brand
  options: Options

  constructor(brand: Brand, order: Order, options: Options) {
    super()

    this.brand = brand
    this.order = order
    this.options = options
  }

  async buildCanvas() {
    const { options, order, brand, fontSize, printLogo } = this

    this.widthSize = options.widthSize

    if (options.fontSize) {
      this.fontSize = options.fontSize
    }

    this.textDistance = fontSize + 12

    console.log('our padding', options.padding)

    this.addSpace()

    if (printLogo) {
      try {
        await this.writeImage(printLogo)

        this.addSpace()
      } catch (error) {
        console.error(error)
        this.writeText(brand.name, { align: 'center' })
      }
    } else {
      this.writeText(brand.name, { align: 'center' })
    }

    this.writeText(brand.phone_number, { align: 'center' })
    this.addSpace()

    this.writeOrderType(order)
    this.addSpace()

    this.addSpace()
    this.writeText(order.name)
    this.writeText(order.phone_number)

    this.writeText(`Placed at ${formatDate(order.placed_at, 'MM/dd/Y h:mma')}`)
    this.addSpace()

    if (order.type === 'delivery') {
      this.writeText(`Delivery at ${order.location_name}`)
      this.addSpace()
    }

    if (!order.instructions || order.instructions === '') {
      order.instructions = 'None'
    }
    this.writeText(`Instructions: ${order.instructions}`)
    this.addLine(20)

    order.items.filter((item) => !item.parent_hash_id)
      .forEach((item, index) => {
        this.writeItem(item)

        const children = order.items.filter((i) => i.parent_hash_id === item.hash_id)

        if (children.length > 0) {
          const prevFontSize = fontSize

          this.setFontSize(Math.round(fontSize * 0.75))

          children.forEach((child) => {
            this.writeItem(child, 30, '*')
          })

          this.setFontSize(prevFontSize)
        }
        if ((index + 1) !== order.items.length) {
          this.addLine(5, 2)
        }
      })

    this.addLine(20)

    this.writeText(`Sub Total: ${money(order.sub_total)}`)

    if (order.rewards_discount) {
      this.writeText(`Rewards Discount: ${money(order.rewards_discount)}`)
    }

    if (order.manual_adjustment) {
      this.writeText(`Manual Adjustment: ${money(order.manual_adjustment)}`)
    }

    if (order.coupon_discount) {
      this.writeText(`Coupon Discount: ${money(order.coupon_discount)}`)
    }

    if (order.delivery_fee) {
      this.writeText(`Delivery Fee: ${money(order.delivery_fee)}`)
    }

    if (order.third_party_delivery_fee) {
      this.writeText(`Third Party Delivery Fee: ${money(order.third_party_delivery_fee)}`)
    }

    if (order.customer_service_fee) {
      this.writeText(`Customer Service Fee: ${money(order.customer_service_fee)}`)
    }

    this.writeText(`Tax: ${money(order.sales_tax)}`)

    const requiresTipInput = order.tip === 0 && order.third_party_delivery_tip === 0 && brand.has_tips_on_arrival

    if (requiresTipInput) {
      this.writeText(`Total Before Tip: ${money(order.total)}`)
      this.addSpace(20)
      this.writeText('Tip:  ___________________')
      this.addSpace(20)
      this.writeText('Total:  ___________________')
    } else if (order.third_party_delivery_tip !== 0) {
      this.writeText(`Third Party Delivery Tip: ${money(order.third_party_delivery_tip)}`)
      this.writeText(`Total: ${money(order.total)}`)
    } else {
      this.writeText(`Tip: ${money(order.tip)}`)
      this.writeText(`Total: ${money(order.total)}`)
    }

    if (requiresTipInput) {
      this.addSpace(80)
      this.writeText('_____________________________')
      this.writeText('Signature')
    }

    this.addSpace()
    this.writeOrderType(order)

    this.addSpace(options.padding)
  }
}
