import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { enhancedFetch } from 'journey-ui'
import { PizzaPrinter } from 'pizza-printer'
import { isTablet } from '../helpers'

function generateUID(length) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random()
      * charactersLength))
  }
  return result
}

/* A hook more so to just keep _app.tsx cleaner. */
export function useDevice(brand: Brand, user: User, setFatalError: Dispatch<SetStateAction<string>>): [Device, Dispatch<SetStateAction<Device>>] {
  const [device, setDevice] = useState<Device>()

  const [masonUuid, setMasonUuid] = useState<string | undefined>()

  useEffect(() => {
    if (masonUuid !== undefined || !brand) {
      return
    }

    if (!isTablet()) {
      setMasonUuid('')
      return
    }

    PizzaPrinter.getByMasonUuid().then(({ uuid }) => {
      setMasonUuid(uuid ?? '')
    }).catch(() => {
      setMasonUuid('')
      // do nothing
    })
  }, [brand, masonUuid])

  useEffect(() => {
    if (!brand || device || !user || !isTablet() || masonUuid === undefined) {
      return
    }

    let uid = localStorage.getItem('deviceUid')

    if (!uid) {
      uid = generateUID(64)
      localStorage.setItem('deviceUid', uid)
    }

    enhancedFetch<Device>('/api/device', {
      method: 'POST',
      body: {
        user_agent: navigator.userAgent,
        mason_uuid: masonUuid,
        uid,
        brand_hash_id: brand.hash_id,
      },
    }).then((fetchedDevice) => {
      setDevice(fetchedDevice)
    }).catch((error) => {
      setFatalError(`Failed to properly setup your device. Please contact us. (${error.message})`)
    })
  }, [brand, device, masonUuid, setFatalError, user])

  return [device, setDevice]
}
