import { createContext, Dispatch, SetStateAction } from 'react'
import { UsePaginateData } from 'journey-ui'

export interface AppContextData {
  brandHashId: string
  unreadChatCount: number
  refreshUnreadChatCount: () => any
  hasOwnerAccess: boolean
  searchOrdersText: string
  setSearchOrdersText: Dispatch<SetStateAction<string>>
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User>>
  orderCardsPaginator: UsePaginateData<OrderCard>
  orderCards: OrderCard[]
  brand: Brand | undefined
  setBrand: Dispatch<SetStateAction<Brand | undefined>>
  alertMilliseconds: number | null
  setAlertMilliseconds: Dispatch<SetStateAction<number | null>>
  device: Device;
  setDevice: Dispatch<SetStateAction<Device>>
  fatalError: string
  setFatalError: Dispatch<SetStateAction<string>>
  bankErrorData: BankErrorData
  setBankErrorData: Dispatch<SetStateAction<BankErrorData>>
  printers: Printer[]
  printerPaginator: UsePaginateData<Printer>
}

export const AppContext = createContext<AppContextData>({} as AppContextData)
