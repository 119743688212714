import { useEffect, useState } from 'react'
import { enhancedFetch, useSocket } from 'journey-ui'
import { isTablet } from '../helpers'

interface HealthCheckError {
  message: string
  created_at: string
}

function sendErrors(deviceHashId, errors) {
  enhancedFetch(`/api/device/${deviceHashId}/send-errors`, {
    method: 'POST',
    body: {
      errors,
    },
  })
    .catch(console.error)
}

function getDateNow() {
  return (new Date()).toUTCString()
}

function saveErrors(errors) {
  window.localStorage.setItem('health-check-errors', JSON.stringify(errors.slice(0, 50)))
}

export function useHealthCheck(brand: Brand, device: Device) {
  const [sentAt, setSentAt] = useState(0)
  const [receivedPingAt, setReceivedPingAt] = useState(0)
  const [errors, setErrors] = useState<HealthCheckError[]>([])

  const deviceHashId = device?.hash_id

  useEffect(() => {
    const healthCheckErrorJson = window.localStorage.getItem('health-check-errors')

    if (healthCheckErrorJson) {
      const jsonErrors = JSON.parse(healthCheckErrorJson)
      if (jsonErrors?.length > 0) {
        sendErrors(deviceHashId, jsonErrors)
      }
    }
  }, [deviceHashId])

  useSocket({
    channelId: deviceHashId ? `device.${deviceHashId}` : '',
    type: 'private',
    eventId: '.health.check',
    onMessage: (msg) => {
      setReceivedPingAt(Date.now())
    },
  })

  useEffect(() => {
    if (!isTablet() || !deviceHashId) {
      return
    }

    if (!deviceHashId) {
      return
    }

    /* An hour with 5 min randomizer. */
    const t = setInterval(() => {
      enhancedFetch(`/api/device/${deviceHashId}/health-check`, { method: 'POST' }).then(() => {
        setSentAt(Date.now())
      }).catch((error) => {
        setErrors((errors) => {
          const newErrors = [...errors, { message: error.message, created_at: getDateNow() }]
          saveErrors(newErrors)

          return newErrors
        })
      })
    }, 3_600_000 - (Math.random() * 300_000))

    return () => clearInterval(t)
  }, [deviceHashId])

  useEffect(() => {
    if (!sentAt) {
      return
    }

    /* Wait 5 seconds, if we didn't get a response from pusher, we got a problem. */
    const t = setTimeout(() => {
      if (!receivedPingAt) {
        setErrors((errors) => {
          const newErrors = [...errors, { message: 'Failed to receive ping in 5 seconds.', created_at: getDateNow() }]
          saveErrors(newErrors)

          return newErrors
        })

        setSentAt(0)
        // do some error collecting
      } else {
        // we are okay.

        // we previously were offline, but now we are back up, but we need to notify ourselves about this!
        if (errors.length > 0) {
          sendErrors(deviceHashId, errors)
        }

        setReceivedPingAt(0)
        setSentAt(0)
        setErrors([])
        saveErrors([])
      }
    }, 5000)

    return () => clearTimeout(t)
  }, [deviceHashId, errors, errors.length, receivedPingAt, sentAt])
}
