import { useFetch } from 'journey-ui'

export function usePrintLogo(brand: Brand): string {
  const [printLogo, setPrintLogo, printLogoFetcher] = useFetch<{ print_logo: string }>({
    url: `/api/brand/${brand?.hash_id}/print-logo`,
    isAllowed: !!brand,
    swrOptions: {
      revalidateOnFocus: false,
    },
  })

  return printLogo?.print_logo
}
